<template>
  <div>
    <vs-row vs-justify="center">
      <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <vs-card>
          <div slot="header">
            <h3>
              {{$t('Edit Account')}}
            </h3>
          </div>
          <div>
            <vs-row  type="flex" vs-justify="space-around" vs-align="center">
              <vs-col vs-w="12" vs-sm="12">
                <template>
                  <div class="vx-row">
                    <div class="vx-col sm:w-1/2 w-full mb-2">
                      <vs-input class="w-full" :label-placeholder="$t('First Name')" v-model="EditedAccountData.first_name"/>
                      <span v-if="errors.first_name" class="text-danger text-sm valdation_error_avatar"
                            v-show="errors.first_name">{{errors.first_name[0]}}</span>
                    </div>
                    <div class="vx-col sm:w-1/2 w-full mb-2">
                      <vs-input class="w-full" :label-placeholder="$t('Last Name')"  v-model="EditedAccountData.last_name"/>
                      <span v-if="errors.last_name" class="text-danger text-sm valdation_error_avatar"
                            v-show="errors.last_name">{{errors.last_name[0]}}</span>
                    </div>
                  </div>
                  <div class="vx-row">
                    <div class="vx-col sm:w-1/2 w-full mb-2">
                      <vs-input class="w-full" :label-placeholder="$t('TC')"  v-model="EditedAccountData.tc"/>
                      <span v-if="errors.tc" class="text-danger text-sm valdation_error_avatar"
                            v-show="errors.tc">{{errors.tc[0]}}</span>
                    </div>
                    <div class="vx-col sm:w-1/2 w-full mb-2">
                      <vs-input type="phone" class="w-full" :label-placeholder="$t('Phone')" v-model="EditedAccountData.mobile"/>
                      <span v-if="errors.mobile" class="text-danger text-sm valdation_error_avatar"
                            v-show="errors.mobile">{{errors.mobile[0]}}</span>
                    </div>
                  </div>
                  <div class="vx-row">
                    <div class="vx-col sm:w-1/2 w-full mb-2">
                      <vs-input type="email" class="w-full" :label-placeholder="$t('Email')" v-model="EditedAccountData.email"/>
                      <span v-if="errors.email" class="text-danger text-sm valdation_error_avatar"
                            v-show="errors.email">{{errors.email[0]}}</span>
                    </div>
                  </div>
                </template>
              </vs-col>
              <vs-col vs-w="12">
                <br/>
                <br/>
                <br/>
              </vs-col>
              <vs-col vs-w="11" align="end">
                <vs-button color="success" type="gradient" @click="ConfirmAccountEdit()" class="mr-3 mb-2">
                  {{$t('Confirm')}}</vs-button>
                <vs-button color="warning" type="gradient" class="mb-2">{{$t('Reset')}}</vs-button>
              </vs-col>
            </vs-row>
          </div>

        </vs-card>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
export default {
  /* eslint-disable */
  data: () => ({
    AccountId:'',
    EditedAccountData:{},
    errors:[],
  }),
  watch:{
    AccountData(val){
      this.EditedAccountData = Object.assign({},val)
    }
  },
  computed:{
    AccountData() {
      return this.$store.getters["account/account"]
    },
  },
  methods: {
    getData(){
        this.$store.dispatch('account/GetAccountData', this.AccountId)
    },
    ConfirmAccountEdit(){
      this.$store.dispatch('account/EditAccount',this.EditedAccountData).then(response=>{
        this.$router.push({
          name: 'Accounts'
        })
      })
      .catch((error) => {
        if(error.response.data.errors)
          this.errors = error.response.data.errors
      })
    }
  },
  mounted() {

  },
  created() {
    this.AccountId=this.$route.params.accountId
    this.getData();
  }
}
</script>
<style>
.select-label-placeholder {
  position: absolute;
}
</style>
